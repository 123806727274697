<template>
  <div>
    <el-dialog title="添加文章" v-model="view" width="1000px" :close-on-click-modal="false"
               :close-on-press-escape="false"
               :show-close="false">
      <el-form label-width="110px">
        <el-form-item label="活动项目">
          <el-select v-model="data.Title" placeholder="请选择" @change="SelectProject" disabled>
            <el-option
                v-for="item in Select.ItemProject"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
          <span class="ItemSet" v-if="data.Title">活动期数：{{ data.TitleNumber }}</span>
          <span class="ItemSet" v-if="data.Title">活动编号：{{ data.ItemNumber }}</span>
        </el-form-item>
        <el-form-item label="标题">
          <el-input v-model="data.ArticleTitle" class="ArticleTitle"></el-input>
        </el-form-item>
        <el-form-item>
          <div v-if="data.TeamID">团队昵称：{{ data.TeamName }}</div>
          <el-table
              v-if="data.TeamID"
              :data="data.TeamMember"
              border
              header-cell-class-name="table-header"
          >
            <el-table-column prop="Name" label="昵称"></el-table-column>
            <el-table-column prop="Mobile" label="手机号码"></el-table-column>
            <el-table-column prop="Position" label="权限"></el-table-column>
            <el-table-column prop="NewTime" label="加入时间"></el-table-column>
            <el-table-column label="操作">
              <template #default="scope">
                <el-checkbox v-model="scope.row.IdNumberSet" @change="IdNumberEdit(scope.$index)">作者</el-checkbox>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item>
          <el-button @click="openTinymce" type="text">文章编辑</el-button>
        </el-form-item>
      </el-form>
      <template #footer>
    <span class="dialog-footer">
           <el-button @click="close">关 闭</el-button>
           <el-button @click="save">添加</el-button>
    </span>
      </template>
    </el-dialog>
    <TeamAdd ref="TeamAdd"></TeamAdd>
    <TinymceView ref="TinymceView"></TinymceView>
  </div>
</template>

<script>

import {fetchProject, FindWorkTeam,} from "@/api/Aritcle/Team";
import TeamAdd from './TeamAdd'
import TinymceView from './WorkEdit/TinymceView'
import {WorkAdd} from "@/api/Aritcle/Index";

export default {
  data() {
    return {
      Select: {
        ItemData: [],
        ItemProject: [],
      },
      view: false,
      data: {}
    }
  },
  components: {
    TeamAdd,
    TinymceView,
  },
  methods: {
    IdNumberEdit(index) {
      let TeamMember = this.data.TeamMember
      for (let i in TeamMember) {
        TeamMember[i].IdNumberSet = false
      }
      TeamMember[index].IdNumberSet = true
      this.data.TeamMember = TeamMember
    },
    save() {
      let data = this.data
      WorkAdd({...data}).then(res => {
        if (res.code == 0) {
          this.$message.success(res.message);
          this.close()
          this.$parent.findWorkList()
        } else {
          this.$message.error(res.message);
        }
      })
    },
    openLookArticle() {
      this.$refs.Work.open(this.data.ContentText)
    },
    openTinymce() {
      this.$refs.TinymceView.openTinymce(this.data.ContentText)
    },
    saveTinymce(data) {
      this.data.ContentText = data
    },
    AddTeam(str) {
      let TeamID = str.TeamID
      let TeamName = str.TeamName
      let TeamMember = JSON.parse(str.TeamMember)
      this.data.TeamID = TeamID
      this.data.TeamName = TeamName
      this.data.TeamMember = TeamMember
    },
    init() {
      let TeamID = this.TeamID

      fetchProject().then(res => {
        let sum = res.sum
        let add = []
        for (let i in sum) {
          add.push({
            value: sum[i].Title,
            label: sum[i].Title,
          })
        }
        this.Select.ItemProject = add
        this.Select.ItemData = sum
        FindWorkTeam({TeamID}).then(res=>{
          let sum=res.sum

          console.log(sum)
          this.data=sum
        })

      })
    },
    SelectProject(value) {
      let ItemData = this.Select.ItemData
      for (let i in ItemData) {
        if (ItemData[i].Title == value) {
          this.data.ItemNumber = ItemData[i].ItemNumber
          this.data.TitleNumber = ItemData[i].TitleNumber
          break;
        }
      }
      this.data.TeamMember = []
      this.data.TeamID = ''
      this.data.TeamName = ''
    },
    openTeam() {
      let ItemNumber = this.data.ItemNumber
      if (ItemNumber) {
        this.$refs.TeamAdd.open(ItemNumber)
      } else {
        this.$message.error('活动项目为空，请检查并选择');
      }

    },
    close() {
      this.view = false
      this.data = {}
    },
    open(TeamID) {
      this.TeamID = TeamID
      this.view = true
      this.init()
    },
  }
}
</script>

<style scoped lang="scss">
.HashDex {
  margin-left: 15px;
}

.red {
  margin-left: 15px;
  color: red;
}

.green {
  margin-left: 15px;
  color: green;
}

.ItemSet {
  margin-left: 15px;
}

.ArticleTitle {
  width: 400px;
}
</style>