import request from '../../utils/request';

export const fetchData = query => {
    return request({
        url: process.env.VUE_APP_URL+'Admin/ArticleIndex/list',
        method: 'get',
        params: query
    });
};

export const fetchRegexData = query => {
    return request({
        url: process.env.VUE_APP_URL+'Admin/ArticleIndex/regex',
        method: 'get',
        params: query
    });
};

export const fetchDetails = query => {
    return request({
        url: process.env.VUE_APP_URL+'Admin/ArticleIndex/details',
        method: 'get',
        params: query
    });
};


export const fetchWorkList = query => {
    return request({
        url: process.env.VUE_APP_URL+'Admin/ArticleIndex/worklist',
        method: 'get',
        params: query
    });
};


export const fetchWorkDetailed = query => {
    return request({
        url: process.env.VUE_APP_URL+'Admin/ArticleIndex/work/detailed',
        method: 'get',
        params: query
    });
};



//团队查询
export const Teamfind = query => {
    return request({
        url: process.env.VUE_APP_URL+'Admin/ArticleIndex/Team/find',
        method: 'get',
        params: query
    });
};

//团队查询(模糊)
export const TeamFindRegex = query => {
    return request({
        url: process.env.VUE_APP_URL+'Admin/ArticleIndex/Team/find/regex',
        method: 'get',
        params: query
    });
};

//封禁文章
export const BanDexEdit = query => {
    return request({
        url: process.env.VUE_APP_URL+'Admin/ArticleIndex/BanDex',
        method: 'post',
        data: query
    });
};

//添加文章
export const WorkAdd = query => {
    return request({
        url: process.env.VUE_APP_URL+'Admin/ArticleIndex/Add',
        method: 'post',
        data: query
    });
};

//添加文章
export const WorkDelete = query => {
    return request({
        url: process.env.VUE_APP_URL+'Admin/ArticleIndex/Delete',
        method: 'post',
        data: query
    });
};