<template>
  <div>
    <el-dialog title="添加团队" v-model="view" width="900px" :close-on-click-modal="false"
               :close-on-press-escape="false"
               :show-close="false">
      <el-form label-width="110px">
        <el-form-item label="活动项目">
          <el-select v-model="Data.Title" placeholder="请选择" @change="SelectProject">
            <el-option
                v-for="item in Select.ItemProject"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
          <span class="ItemSet" v-if="Data.Title">活动期数：{{ Data.TitleNumber }}</span>
          <span class="ItemSet" v-if="Data.Title">活动编号：{{ Data.ItemNumber }}</span>
        </el-form-item>
        <el-form-item label="团队昵称">
          <el-input v-model="Data.TeamName"></el-input>
        </el-form-item>
        <el-form-item label="团队成员">
          <el-button class="UserAdd" @click="openUser">添加成员</el-button>
          <el-table
              :data="Data.TeamMember"
              border
              height="200px"
              header-cell-class-name="table-header"
              size="small"
          >
            <el-table-column prop="Name" label="姓名" width="180"></el-table-column>
            <el-table-column prop="Mobile" label="手机号码"></el-table-column>
            <el-table-column  label="权限">
              <template #default="scope">
                <el-select v-model="scope.row.Position" placeholder="请选择权限">
                  <el-option
                      v-for="item in Select.Position"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column>
              <template #default="scope">
                <el-button type="text" class="red" @click="rmrove(scope.$index)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
      <template #footer>
    <span class="dialog-footer">
        <el-button @click="save">添 加</el-button>
        <el-button @click="close">关 闭</el-button>
    </span>
      </template>
    </el-dialog>
    <TeamAddUser ref="TeamAddUser"></TeamAddUser>
  </div>
</template>

<script>
import TeamAddUser from './TeamAddUser'
import {AddTeam, fetchProject} from "@/api/Aritcle/Team";

export default {
  data() {
    return {
      view: false,
      Select: {
        ItemData: [],
        ItemProject: [],
        Position:[
          {
            value: '所有权',
            label: '所有权'
          },
          {
            value: '管理员',
            label: '管理员'
          },
          {
            value: '普通',
            label: '普通'
          },
        ]
      },
      Data: {
        ItemNumber: '',
        TeamName: '',
        TeamMember: [],
      }
    }
  },
  methods: {
    rmrove(index) {
      let TeamMember = JSON.parse(JSON.stringify(this.Data.TeamMember))
      this.$confirm("是否删除该用户？", "提示", {
        type: "warning"
      }).then(() => {
        TeamMember.splice(index, 1);
        this.Data.TeamMember = TeamMember
      })
    },
    openUser() {
      this.$refs.TeamAddUser.open()
    },
    AddUser(str) {
      let TeamMember = JSON.parse(JSON.stringify(this.Data.TeamMember))
      let IdNumber = str.IdNumber
      let Mobile = str.Mobile
      let Name = str.Name
      let add = {IdNumber, Mobile, Name}
      TeamMember.push(add)
      this.Data.TeamMember = TeamMember
    },
    SelectProject(value) {
      let ItemData = this.Select.ItemData
      for (let i in ItemData) {
        if (ItemData[i].Title == value) {
          this.Data.ItemNumber = ItemData[i].ItemNumber
          this.Data.TitleNumber = ItemData[i].TitleNumber
          break;
        }
      }
    },
    open() {
      this.view = true
      this.init()
    },
    init() {
      fetchProject().then(res => {
        let sum = res.sum
        let add = []
        for (let i in sum) {
          add.push({
            value: sum[i].Title,
            label: sum[i].Title,
          })
        }
        this.Select.ItemProject = add
        this.Select.ItemData = sum
      })
    },
    save() {
      let Data = JSON.parse(JSON.stringify(this.Data))
      AddTeam(Data).then(res=>{
        if (res.code == 0) {
          this.$message.success(res.message);
          this.$parent.hadeFind()
          this.close()
        } else {
          this.$message.error(res.message);
        }
      })
    },
    closeData() {
      let Data = JSON.parse(JSON.stringify(this.Data))
      for (let i in Data) {
        Data[i] = ''
        // console.log(i)
      }
      Data.TeamMember = []
      this.Data = Data
    },
    close() {
      this.closeData()
      this.view = false
    }
  },
  components: {
    TeamAddUser
  }
}
</script>

<style scoped lang="scss">
.UserAdd {
  margin-bottom: 10px;
}

.ItemSet {
  margin-left: 15px;
}

.red {
  color: red;
}
</style>