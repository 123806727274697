<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 用户管理
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="container">
      <div class="handle-box">
        <el-input v-model="query.name" placeholder="请输入搜索关键词" class="handle-input mr10"></el-input>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
        <el-button type="primary" icon="el-icon-close" @click="closeSearch">重置</el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">添加</el-button>
      </div>
      <el-table
          v-loading="loading"
          :data="tableData"
          border
          height="400px"
          header-cell-class-name="table-header"
      >
        <el-table-column prop="Name" label="姓名" width="180"></el-table-column>
        <el-table-column prop="Mobile" label="手机号码"></el-table-column>
        <el-table-column prop="NewTime" label="加入时间"></el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="query.pageIndex"
            :page-size="query.pageSize"
            :total="query.pageTotal"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
    <UserAdd ref="UserAdd"></UserAdd>
  </div>
</template>

<script>
import {fetchData, fetchRegexData} from '../../api/User'
import UserAdd from './Page/Add'

export default {
  data() {
    return {
      query: {
        name: '',
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      loading: true,
      tableData: [],
    };
  },
  mounted() {
    this.hadeFind()
  },
  methods: {
    handleAdd() {
      this.$refs.UserAdd.open()
    },
    closeSearch() {
      this.hadeFind()
    },
    hadeFind() {
      this.tableData = []
      let skip = this.query.pageIndex
      this.query.name = ''
      this.loading = true
      fetchData({skip}).then(res => {
        if (res.code == 0) {
          this.tableData = res.sum
          this.query.pageTotal = res.pageTotal
          this.loading = false
        } else {
          this.$message.error(res.message);
        }
      })
    },
    //搜索
    handleSearch() {
      this.loading = true
      let name = this.query.name
      this.tableData = []
      fetchRegexData({name}).then(res => {
        if (res.code == 0) {
          this.tableData = res.sum
          this.query.pageTotal = 1
          this.loading = false
        } else {
          this.$message.error(res.message);
        }
      })
    },
    // 分页导航
    handlePageChange(val) {
      this.query.pageIndex = val
      this.hadeFind();
    }
  },
  components: {
    UserAdd
  }
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.mr10 {
  margin-right: 10px;
}

</style>
