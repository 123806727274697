<template>
  <div class="header">

    <div class="logo"></div>
    <div class="title">万人添书后台管理</div>
    <div class="header-right">
      <div class="header-user-con">
        <!-- 用户头像 -->
        <div class="user-avator">
        </div>
        <!-- 用户名下拉菜单 -->
        <el-dropdown class="user-name" trigger="click" @command="handleCommand">
                    <span class="el-dropdow-link">
                        {{ AdminUser.Name }}
                        <i class="el-icon-caret-bottom"></i>
                    </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item divided command="loginout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      fullscreen: false,
      AdminUser: {}
    };
  },
  computed: {
    collapse() {
      return this.$store.state.collapse;
    }
  },
  mounted() {
    let AdminUser = localStorage.getItem('AdminUser');
    AdminUser = JSON.parse(AdminUser)
    this.AdminUser = AdminUser
  },
  methods: {
    // 用户名下拉菜单选择事件
    handleCommand(command) {
      if (command == "loginout") {
        localStorage.removeItem("AdminUser");
        this.$router.push("/login");
      }
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  font-size: 22px;
  color: #000;
  background-color: #f5f5f5;
  border-bottom: 1px solid #aaaaaa;
}

.header .logo {
  float: left;
  width: 70px;
  height: 70px;
  background-image: url("../../public/logo.png");
  background-position: center center;
  background-size: 30px 30px;
  background-repeat: no-repeat;
}

.header .title {
  float: left;
  font-size: 15px;
  height: 70px;
  line-height: 70px;
}

.header .title h3 {
  display: inline;
  margin-right: 20px;
}


.header-right {
  float: right;
  padding-right: 50px;
}

.header-user-con {
  display: flex;
  height: 70px;
  align-items: center;
}

.user-name {
  margin-left: 10px;
}

.user-avator {
  margin-left: 20px;
}

.user-avator img {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
</style>
