<template>
  <el-dialog title="添加用户" v-model="view" width="500px" :close-on-click-modal="false"
             :close-on-press-escape="false"
             :show-close="false">
    <el-form label-width="70px">
      <el-form-item label="昵称">
        <el-input v-model="form.Name"></el-input>
      </el-form-item>
      <el-form-item label="手机号码">
        <el-input v-model="form.Mobile"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
    <span class="dialog-footer">
        <el-button @click="save">添 加</el-button>
        <el-button @click="close">关 闭</el-button>
    </span>
    </template>
  </el-dialog>
</template>

<script>

import {addData} from '../../../api/User'

export default {
  data() {
    return {
      view: false,
      form: {}
    }
  },
  methods: {
    open(){
      this.view=true
    },
    save() {
      let form = this.form
      addData(form).then(res => {
        if (res.code == 0) {
          this.form={}
          this.view=false
          this.$message.success(res.message)
          this.$parent.hadeFind()
        } else {
          this.$message.error(res.message);
        }
      })
    },
    close() {
      this.view=false
    }
  }

}
</script>

<style scoped lang="scss">

</style>