import request from '../../utils/request';

export const fetchData = query => {
    return request({
        url: process.env.VUE_APP_URL+'Admin/AritcleProject/list',
        method: 'get',
        params: query
    });
};


export const EditSaveUp = query => {
    return request({
        url: process.env.VUE_APP_URL+'Admin/AritcleProject/up',
        method: 'post',
        data: query
    });
};

export const EditSaveAdd = query => {
    return request({
        url: process.env.VUE_APP_URL+'Admin/AritcleProject/add',
        method: 'post',
        data: query
    });
};

export const DeleteProject = query => {
    return request({
        url: process.env.VUE_APP_URL+'Admin/AritcleProject/delete',
        method: 'post',
        data: query
    });
};

export const DeleteProjectAll = query => {
    return request({
        url: process.env.VUE_APP_URL+'Admin/AritcleProject/delete/all',
        method: 'post',
        data: query
    });
};



