<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 评论管理
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="container">
      <el-table
          v-loading="loading"
          :data="tableData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
      >
        <el-table-column prop="Articles_id" label="文章编号" width="180"></el-table-column>
        <el-table-column prop="Name" label="昵称"></el-table-column>
        <el-table-column prop="Mobile" label="手机号码"></el-table-column>
        <el-table-column prop="TextContents" label="评论内容"  width="200"></el-table-column>
          <el-table-column prop="NewTime" label="创建时间"></el-table-column>
        <el-table-column >
          <template #default="scope">
           <el-button type="text" @click="openDetailed(scope.row.TextContent)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="query.pageIndex"
            :page-size="query.pageSize"
            :total="query.pageTotal"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
<Detailed ref="Detailed"></Detailed>
  </div>
</template>

<script>
import {fetchData} from '../../api/Comment'
import Detailed from './Page/Detailed'

export default {
  data() {
    return {
      query: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      loading: true,
      tableData: []
    };
  },
  components:{
    Detailed
  },
  mounted() {
    this.hadeFind()
  },
  methods: {
    openDetailed(TextContent){
      this.$refs.Detailed.open(TextContent)
    },
    hadeFind() {
      let skip = this.query.pageIndex
      this.loading = true
      fetchData({skip}).then(res => {
        if (res.code == 0) {
          let sum=res.sum
          for (let item in sum){
            let TextContent=sum[item].TextContent
            if (TextContent.length>=11){
              let Text=TextContent.slice(0,11)
              Text=Text+'...'
              sum[item].TextContents=Text
            }else {
              sum[item].TextContents=TextContent
            }
          }
          this.tableData = res.sum
          this.query.pageTotal = res.pageTotal
          this.loading = false
        } else {
          this.$message.error(res.message);
        }
      })
    },
    //搜索
    handleSearch() {

    },
    // 分页导航
    handlePageChange(val) {
      this.query.pageIndex = val
      this.hadeFind();
    }
  }
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-input {
  width: 300px;
  display: inline-block;
  /*color: #ff5860;*/
}

.table {
  width: 100%;
  font-size: 14px;
}

.mr10 {
  margin-right: 10px;
}

</style>
