<template>
  <el-dialog title="移动作品" v-model="view" width="1000px" :close-on-click-modal="false"
             :close-on-press-escape="false"
             :show-close="false">
    <el-table
        v-loading="loading"
        :data="tableData"
        border
        header-cell-class-name="table-header"
    >
      <el-table-column prop="ItemNumber" label="活动编号" width="80"></el-table-column>
      <el-table-column prop="Commit" label="活动项目" width="180"></el-table-column>
      <el-table-column prop="Articles_id" label="文章编号" width="120"></el-table-column>
      <el-table-column prop="Name" label="昵称" width="120"></el-table-column>
      <el-table-column prop="Title" label="标题" :show-overflow-tooltip="true" width="180"></el-table-column>
      <el-table-column prop="NewTime" label="发表日期"></el-table-column>
      <el-table-column width="80"  fixed="right">
        <template #default="scope">
          <el-button
              type="text"
              @click="HadeSelect(scope.row.Articles_id)"
          >选中
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="query.pageTotal"
          @current-change="handlePageChange"
      ></el-pagination>
    </div>
    <template #footer>
    <span class="dialog-footer">
           <el-button @click="close">关 闭</el-button>
    </span>
    </template>
  </el-dialog>

</template>

<script>

import {fetchDataItemNumber, SelectWork} from "@/api/Aritcle/Team";

export default {
  data() {
    return {
      view: false,
      tableData: [],
      query: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      loading: false,
      ItemNumber:'',
      TeamID:''
    }
  },
  methods: {
    HadeSelect(Articles_id){
      let TeamID=this.TeamID
      this.$confirm('此操作将该文章移至作品集, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        SelectWork({Articles_id,TeamID}).then(res=>{
          if (res.code == 0) {
            this.$message.success(res.message);
            this.close()
            this.$parent.findWorkList()
          } else {
            this.$message.error(res.message);
          }
        })
      });

    },
    open(ItemNumber,TeamID) {
      this.ItemNumber=ItemNumber
      this.TeamID=TeamID
      this.view=true
      this.hadeFind()
    },
    close(){
      this.ItemNumber=''
      this.tableData = []
      this.query.pageIndex=1
      this.view=false

    },
    hadeFind() {
      let skip = this.query.pageIndex
      let ItemNumber=this.ItemNumber
      let TeamID=this.TeamID
      this.loading = true
      fetchDataItemNumber({skip,ItemNumber,TeamID}).then(res => {
        if (res.code == 0) {
          this.tableData = res.sum
          this.query.pageTotal = res.pageTotal
          this.loading = false
        } else {
          this.$message.error(res.message);
        }
      })
    },
    // 分页导航
    handlePageChange(val) {
      this.loading = true
      this.query.pageIndex = val
      this.hadeFind();
    }
  }
}
</script>

<style scoped lang="scss">

</style>