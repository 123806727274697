<template>
  <el-dialog title="团队成员" v-model="view" width="900px" :close-on-click-modal="false"
             :close-on-press-escape="false"
             :show-close="false">
    <el-table
        :data="tableData"
        border
        header-cell-class-name="table-header"
    >
      <el-table-column prop="Name" label="昵称"></el-table-column>
      <el-table-column prop="Mobile" label="手机号码"></el-table-column>
      <el-table-column prop="Position" label="权限"></el-table-column>
      <el-table-column prop="NewTime" label="加入时间"></el-table-column>
    </el-table>

    <template #footer>
    <span class="dialog-footer">
           <el-button @click="close">关 闭</el-button>
    </span>
    </template>
  </el-dialog>

</template>

<script>
export default {
  data() {
    return {
      view: false,
      tableData: []
    }
  },
  methods: {
    open(data) {
      this.tableData = JSON.parse(JSON.stringify(data))
      this.view=true
    },
    close(){
      this.tableData = []
      this.view=false
    }
  }
}
</script>

<style scoped lang="scss">

</style>