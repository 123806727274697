<template>
  <el-dialog title="添加团队" v-model="view" width="900px" :close-on-click-modal="false"
             :close-on-press-escape="false"
             :show-close="false">
    <div class="handle-box">
      <el-input v-model="query.name" placeholder="请输入搜索关键词" class="handle-input"></el-input>
      <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
      <el-button type="primary" icon="el-icon-close" @click="closeSearch">重置</el-button>
    </div>
    <el-table
        v-loading="loading"
        :data="tableData"
        border
        height="400"
        header-cell-class-name="table-header"
    >
      <el-table-column prop="ItemNumber" label="活动编号" width="180"></el-table-column>
      <el-table-column prop="Title" label="活动项目"></el-table-column>
      <el-table-column prop="TeamName" label="团队昵称" :show-overflow-tooltip="true" width="180"></el-table-column>
      <el-table-column prop="NewTime" label="加入时间"></el-table-column>
      <el-table-column>
        <template #default="scope">
          <el-button type="text" @click="AddUser(scope.row)">选中</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="query.pageTotal"
          @current-change="handlePageChange"
      ></el-pagination>
    </div>
    <template #footer>
    <span class="dialog-footer">
           <el-button @click="close">关 闭</el-button>
    </span>
    </template>
  </el-dialog>
</template>

<script>

import {Teamfind, TeamFindRegex} from '@/api/Aritcle/Index'


export default {
  data() {
    return {
      query: {
        name: '',
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      loading: true,
      view: false,
      tableData: [],
    };
  },
  mounted() {

  },
  methods: {
    open(ItemNumber) {
      this.view = true
      this.ItemNumber = ItemNumber
      this.hadeFind()
    },
    close() {
      this.view = false
      this.tableData = []
    },
    AddUser(str) {
      this.$parent.AddTeam(str)
      this.close()
    },
    closeSearch() {
      this.tableData = []
      this.hadeFind()
    },
    hadeFind() {
      this.tableData = []
      let ItemNumber = this.ItemNumber
      let skip = this.query.pageIndex
      this.query.name = ''
      this.loading = true
      Teamfind({skip, ItemNumber}).then(res => {
        if (res.code == 0) {
          this.tableData = res.sum
          this.query.pageTotal = res.pageTotal
          this.loading = false
        } else {
          this.$message.error(res.message);
        }
      })
    },
    //搜索
    handleSearch() {
      this.tableData = []
      this.loading = true
      let ItemNumber = this.ItemNumber
      let name = this.query.name
      TeamFindRegex({name, ItemNumber}).then(res => {
        if (res.code == 0) {
          this.tableData = res.sum
          this.query.pageTotal = 1
          this.loading = false
        } else {
          this.$message.error(res.message);
        }
      })
    },
    // 分页导航
    handlePageChange(val) {
      this.query.pageIndex = val
      this.hadeFind();
    }
  }
};
</script>

<style scoped lang="scss">
.handle-box {
  margin-bottom: 15px;
}

.handle-input {
  width: 200px;
  margin-right: 15px;
}
</style>