import request from '../utils/request';

//列表普通查询
export const fetchData = query => {
    return request({
        url: process.env.VUE_APP_URL+'Admin/User/list',
        method: 'get',
        params: query
    });
};


//模糊查询用户
export const fetchRegexData = query => {
    return request({
        url: process.env.VUE_APP_URL+'Admin/User/regex',
        method: 'get',
        params: query
    });
};

//添加用户
export const addData = query => {
    return request({
        url: process.env.VUE_APP_URL+'Admin/User/add',
        method: 'post',
        data: query
    });
};
