import request from '../utils/request';

//登录
export const LoginSet = query => {
    return request({
        url: process.env.VUE_APP_URL+'Admin/Login/Verification',
        method: 'post',
        data: query
    });
};


//查询权限
export const JurisdictionSet = query => {
    return request({
        url: process.env.VUE_APP_URL+'Admin/Login/Jurisdiction',
        method: 'get',
        params: query
    });
};