<template>
  <div>
    <el-dialog :title="data.Title" v-model="view" width="1000px" :close-on-click-modal="false"
               :close-on-press-escape="false"
               :show-close="false">
      <el-form label-width="110px">
        <el-row>
          <el-col :span="7">
            <el-form-item label="活动编号">{{ data.ItemNumber }}</el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="活动项目">{{ data.Title }}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="7">
            <el-form-item label="创建日期">{{ data.NewTime }}</el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="团队昵称">{{ data.TeamName }}</el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="团队成员">
              <el-button type="text" @click="openTeam">详情</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-button @click="openWork">作品详情</el-button>
        </el-form-item>

      </el-form>

      <template #footer>
    <span class="dialog-footer">
           <el-button @click="close">关 闭</el-button>
    </span>
      </template>
    </el-dialog>
    <TeamMember ref="TeamMember"></TeamMember>
    <ToWork ref="ToWork"></ToWork>
  </div>
</template>

<script>
import {fetchDetails} from "@/api/Aritcle/Index";
import TeamMember from './TeamMember'
import ToWork from './ToWork'
export default {
  data() {
    return {
      view: false,
      data: {}
    }
  },
  components:{
    TeamMember,
    ToWork
  },
  methods: {
    openWork(){
      let TeamID=this.data.TeamID
      this.$refs.ToWork.open(TeamID)
    },
    openTeam(){
      let TeamMember=this.data.TeamMember
      this.$refs.TeamMember.open(TeamMember)
    },
    BanDex() {

    },
    close(){
      this.view = false
      this.data={}
    },
    open(str) {
      this.view = true
      let data = JSON.parse(JSON.stringify(str))
      let TeamID = data.TeamID
      fetchDetails({TeamID}).then(res => {
        let sum = res.sum
        data={...data,...sum}
        this.data = data
      })

    },
    openHash() {
      this.$alert(`
       <div>哈希数: ${this.data.Hash}</div>
      <div>存证编号: ${this.data.Numbering}</div>
      <div>杭互法证据编号:${this.data.EvidenceNumber}</div>
      `, {
        dangerouslyUseHTMLString: true,
      });

    }
  }
}
</script>

<style scoped lang="scss">
.HashDex {
  margin-left: 15px;
}

.red {
  margin-left: 15px;
  color: red;
}

.green {
  margin-left: 15px;
  color: green;
}
</style>