<template>
  <div>
    <el-dialog title="作品集" v-model="view" width="1000px" :close-on-click-modal="false"
               :close-on-press-escape="false"
               :show-close="false">
      <el-container class="container">
        <el-aside width="200px" class="aside">
          <div class="block" v-for="(item,index) in ListData" :key="index" @click="openWorkDai(item.Articles_id)">
            {{ item.Title }}
          </div>
        </el-aside>
        <el-main class="main" v-html="TextData">

        </el-main>
      </el-container>

      <template #footer>
    <span class="dialog-footer">
       <el-button @click="openAdd">添加文章</el-button>
       <el-button @click="openMove">移动文章</el-button>
       <el-button @click="close">关 闭</el-button>
    </span>
      </template>
    </el-dialog>
    <MoveWork ref="MoveWork"></MoveWork>
    <AddWork ref="AddWork"></AddWork>
  </div>
</template>

<script>
import {fetchWorkDetails, fetchWorkList} from "@/api/Aritcle/Team";
import MoveWork from './MoveWork'
import AddWork from './AddWork'

export default {
  data() {
    return {
      view: false,
      TeamID: '',
      ListData: [],
      TextData: '',
      ItemNumber: ''
    }
  },
  methods: {
    openMove() {
      let ItemNumber = this.ItemNumber
      let TeamID = this.TeamID
      this.$refs.MoveWork.open(ItemNumber, TeamID)
    },
    openAdd() {
      let TeamID=this.TeamID
      this.$refs.AddWork.open(TeamID)
    },
    open(TeamID) {
      this.view = true
      this.TeamID = TeamID
      this.TextData = ''
      this.ListData = []
      this.findWorkList()
    },
    findWorkList() {
      let TeamID = this.TeamID
      fetchWorkList({TeamID}).then(res => {
        let data = res.data
        this.ItemNumber = res.ItemNumber
        this.ListData = data
        this.openWorkDai(data[0].Articles_id)
      })
    },
    openWorkDai(Articles_id) {
      this.TextData = ''
      fetchWorkDetails({Articles_id}).then(res => {
        let data = res.data
        this.TextData = data.ContentText
      })
    },
    close() {
      this.view = false
    }
  },
  components: {
    MoveWork,
    AddWork
  }
}
</script>

<style scoped lang="scss">
.container {
  height: 550px;
  padding: 0;
  border: 0;

  .aside {
    height: 100%;
    overflow-y: auto;

    .block {
      width: 100%;
      height: 40px;
      background-color: #f8f8f8;
      margin-bottom: 10px;
      cursor: pointer;
      line-height: 40px;
      text-align: center;
      user-select: none;
    }

    .block::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }

    .block::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 20px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #535353;
    }

    .block::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 20px;
      background: #ededed;
    }
  }

  .main {
    overflow: auto;
    height: 100%;
    user-select: none;
  }

  .main::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }

  .main::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 20px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #535353;
  }

  .main::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    background: #ededed;
  }

}
</style>