<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 文章管理
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="container">
      <div class="handle-box">
        <el-input v-model="query.name" placeholder="请输入搜索关键词" class="handle-input mr10"></el-input>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
        <el-button type="primary" icon="el-icon-s-order" @click="openAdd">添加</el-button>
        <el-button type="text" style="margin-left: 30px" @click="ResetSearch">重置</el-button>
      </div>
      <el-table
          v-loading="loading"
          :data="tableData"
          border
          height="500px"
          header-cell-class-name="table-header"
      >
        <el-table-column prop="ItemNumber" label="活动编号" width="80"></el-table-column>
        <el-table-column prop="Commit" label="活动项目" width="180"></el-table-column>
        <el-table-column prop="Articles_id" label="文章编号" width="120"></el-table-column>
        <el-table-column label="贴子状态" width="80" align="center">
          <template #default="scope">
            <el-tag type="success" v-if="scope.row.Shield=='0'">显示</el-tag>
            <el-tag type="warning" v-else>封禁</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="Title" label="标题" :show-overflow-tooltip="true" width="180"></el-table-column>
        <el-table-column prop="NewTime" label="发表日期" width="180"></el-table-column>
        <el-table-column label="存证信息" width="80">
          <template #default="scope">
            <el-tag type="success" v-if="scope.row.Hash">已存证</el-tag>
            <el-tag type="danger" v-else>未存证</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="Give" label="点赞" width="80"></el-table-column>
        <el-table-column prop="Comment" label="评论" width="80"></el-table-column>
        <el-table-column prop="Pv" label="点击量" width="80"></el-table-column>
        <el-table-column prop="Forward" label="转发量"></el-table-column>
        <el-table-column width="150" align="center" fixed="right">
          <template #default="scope">
            <el-button
                type="text"
                @click="openDetailed(scope.row)"
            >操作
            </el-button>
            <el-button
                class="red"
                type="text"
                @click="Editdelete(scope.row.Articles_id)"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="query.pageIndex"
            :page-size="query.pageSize"
            :total="query.pageTotal"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
    <SettingsPanel ref="SettingsPanel"></SettingsPanel>
    <IndexAdd ref="IndexAdd"></IndexAdd>
  </div>
</template>

<script>

import IndexAdd from './Page/IndexAdd'
import {fetchData, fetchRegexData, WorkDelete} from "@/api/Aritcle/Index";
import SettingsPanel from './Page/SettingsPanel'

export default {
  components: {
    SettingsPanel,
    IndexAdd
  },
  data() {
    return {
      query: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      loading: false,
      tableData: [],
    };
  },
  mounted() {
    this.hadeFind()
  },
  methods: {
    Editdelete(Articles_id){
      this.$confirm('是否确认删除该文章？', "提示", {
        type: "warning"
      }).then(()=> {
        WorkDelete({Articles_id}).then(res=>{
          if (res.code == 0) {
            this.$message.success(res.message);
            this.hadeFind()
          } else {
            this.$message.error(res.message);
          }
        })
      })
    },
    openDetailed(str) {
      this.$refs.SettingsPanel.open(str)
    },
    openAdd() {
      this.$refs.IndexAdd.open()
    },
    handleSearch() {
      this.loading = true
      let name = this.query.name
      fetchRegexData({name}).then(res => {
        if (res.code == 0) {
          let data = res.sum
          this.tableData = data
          this.query.pageTotal = 1
          this.loading = false
        } else {
          this.$message.error(res.message);
        }
      })
    },
    ResetSearch() {
      this.hadeFind()
    },
    hadeFind() {
      this.tableData = []
      let skip = this.query.pageIndex
      this.loading = true
      fetchData({skip}).then(res => {
        if (res.code == 0) {
          this.tableData = res.sum
          this.query.pageTotal = res.pageTotal
          this.loading = false
        } else {
          this.$message.error(res.message);
        }
      })
    },
    // 分页导航
    handlePageChange(val) {
      this.loading = true
      this.query.pageIndex = val
      this.hadeFind();
    }
  }
};
</script>

<style scoped lang="scss">
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}


.mr10 {
  margin-right: 10px;
}

.upload-block {
  ::v-deep .el-upload--text {
    width: auto;
    height: auto;
    border: none;
  }

  display: inline-block;
  float: left;
}

.avatar {
  display: inline-block;
  height: 60px;
  width: 160px;
  text-align: center;
  line-height: 40px;
  margin-left: 20px;

  span {
    color: #00a854;
    font-size: 12px;
    margin-right: 20px;
  }
}

.red {
  color: red;
}

.green {
  color: green;
}
</style>
