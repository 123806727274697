<template>
  <div>
    <el-dialog :title="data.Title" v-model="view" width="1000px" :close-on-click-modal="false"
               :close-on-press-escape="false"
               :show-close="false">
      <el-form label-width="110px">
        <el-row>
          <el-col :span="7">
            <el-form-item label="活动编号">{{ data.ItemNumber }}</el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="活动项目">{{ data.Commit }}</el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="文章编号">{{ data.Articles_id }}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="7">
            <el-form-item label="发表日期">{{ data.NewTime }}</el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="贴子状态">
              <el-tag type="success" v-if="data.Shield=='0'">正常</el-tag>
              <el-tag type="danger" v-else>封禁</el-tag>
              <el-button type="text" class="red" @click="BanDex" v-if="data.Shield=='0'">封禁</el-button>
              <el-button type="text" class="green" @click="BanDex" v-else>解封</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="存证状态">
              <el-tag type="success" v-if="data.Hash">已存证</el-tag>
              <el-tag type="danger" v-else>未存证</el-tag>
              <el-button type="text" class="HashDex" @click="openHash" v-if="data.Hash">哈系数详情</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="7">
            <el-form-item label="团队昵称">{{ data.TeamName }}</el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="团队成员">
              <el-button type="text" @click="openTeam">详情</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-button @click="openWork">作品详情</el-button>
        </el-form-item>

      </el-form>

      <template #footer>
    <span class="dialog-footer">
           <el-button @click="close">关 闭</el-button>
    </span>
      </template>
    </el-dialog>
    <TeamMember ref="TeamMember"></TeamMember>
    <Work ref="Work"></Work>
  </div>
</template>

<script>
import {BanDexEdit, fetchDetails} from "@/api/Aritcle/Index";
import TeamMember from '../../Team/Page/TeamMember'
import Work from './Work'

export default {
  data() {
    return {
      view: false,
      data: {}
    }
  },
  components: {
    TeamMember,
    Work
  },
  methods: {
    openWork() {
      let Articles_id = this.data.Articles_id
      this.$refs.Work.open(Articles_id)
    },
    openTeam() {
      let TeamMember = this.data.TeamMember
      this.$refs.TeamMember.open(TeamMember)
    },
    BanDex() {
      let Articles_id = this.data.Articles_id
      let Shield = this.data.Shield
      let text=''
      if (Shield==0){
        text='是否封禁该文章？'
      }else {
        text='是否解禁该文章？'
      }

      this.$confirm(text, "提示", {
        type: "warning"
      }).then(()=>{
        BanDexEdit({Articles_id}).then(res => {
          if (res.code == 0) {
            this.$message({
              message: res.message,
              type: 'success'
            });
            let data = this.data
            let sum = res.sum
            data = {...data, ...sum}
            this.data = data
            this.$parent.hadeFind()
          } else {
            this.$message({
              message: res.message,
              type: 'warning'
            });
          }
        })
      })
    },
    close() {
      this.view = false
      this.data = {}
    },
    open(str) {
      this.view = true
      let data = JSON.parse(JSON.stringify(str))
      let TeamID = data.TeamID
      fetchDetails({TeamID}).then(res => {
        let sum = res.sum
        data = {...data, ...sum}
        this.data = data
      })

    },
    openHash() {
      this.$alert(`
       <div>哈希数: ${this.data.Hash}</div>
      <div>存证编号: ${this.data.Numbering}</div>
      <div>杭互法证据编号:${this.data.EvidenceNumber}</div>
      `, {
        dangerouslyUseHTMLString: true,
      });

    }
  }
}
</script>

<style scoped lang="scss">
.HashDex {
  margin-left: 15px;
}

.red {
  margin-left: 15px;
  color: red;
}

.green {
  margin-left: 15px;
  color: green;
}
</style>