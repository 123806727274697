import request from '../../utils/request';

export const fetchData = query => {
    return request({
        url: process.env.VUE_APP_URL+'Admin/ArticleTeam/list',
        method: 'get',
        params: query
    });
};

export const fetchRegexData = query => {
    return request({
        url: process.env.VUE_APP_URL+'Admin/ArticleTeam/regex',
        method: 'get',
        params: query
    });
};


export const fetchWorkList = query => {
    return request({
        url: process.env.VUE_APP_URL+'Admin/ArticleTeam/Work/list',
        method: 'get',
        params: query
    });
};


export const fetchWorkDetails = query => {
    return request({
        url: process.env.VUE_APP_URL+'Admin/ArticleTeam/Work/details',
        method: 'get',
        params: query
    });
};


//根据活动编号查询
export const fetchDataItemNumber = query => {
    return request({
        url: process.env.VUE_APP_URL+'Admin/ArticleTeam/list/ItemNumber',
        method: 'get',
        params: query
    });
};

//团队移动作品
export const SelectWork = query => {
    return request({
        url: process.env.VUE_APP_URL+'Admin/ArticleTeam/Work/Select',
        method: 'post',
        data: query
    });
};

//删除团队
export const DeleteTeam = query => {
    return request({
        url: process.env.VUE_APP_URL+'Admin/ArticleTeam/Delete',
        method: 'post',
        data: query
    });
};

//查询项目总对象
export const fetchProject = query => {
    return request({
        url: process.env.VUE_APP_URL+'Admin/ArticleTeam/Project',
        method: 'get',
        params: query
    });
};

//添加团队
export const AddTeam = query => {
    return request({
        url: process.env.VUE_APP_URL+'Admin/ArticleTeam/Add',
        method: 'post',
        data: query
    });
};

//添加文章（团队）查询
export const FindWorkTeam = query => {
    return request({
        url: process.env.VUE_APP_URL+'Admin/ArticleTeam/Find/Work/Team',
        method: 'get',
        params: query
    });
};



