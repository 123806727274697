<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 团队管理
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="container">
      <div class="handle-box">
        <el-input v-model="query.name" placeholder="请输入搜索关键词" class="handle-input mr10"></el-input>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
        <el-button type="primary" icon="el-icon-s-order" @click="openAdd">添加</el-button>
        <el-button type="text" style="margin-left: 30px" @click="ResetSearch">重置</el-button>
      </div>
      <el-table
          v-loading="loading"
          :data="tableData"
          border
          height="600px"
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
      >
        <el-table-column prop="ItemNumber" label="活动编号" width="80"></el-table-column>
        <el-table-column prop="Title" label="活动项目" width="180"></el-table-column>
        <el-table-column prop="TeamName" label="团队昵称" :show-overflow-tooltip="true" width="180"></el-table-column>
        <el-table-column prop="WorkNumber" label="作品数" width="180"></el-table-column>
        <el-table-column label="团队成员" width="180">
          <template #default="scope">
            {{ scope.row.TeamMember.length }}位
          </template>
        </el-table-column>
        <el-table-column prop="NewTime" label="创建时间"></el-table-column>

        <el-table-column width="180" align="center" fixed="right">
          <template #default="scope">
            <el-button
                type="text"
                @click="openDetailed(scope.row)"
            >操作
            </el-button>
            <el-button
                type="text"
                class="red"
                @click="removeTeam(scope.row.TeamID)"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="query.pageIndex"
            :page-size="query.pageSize"
            :total="query.pageTotal"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
    <TeamPanel ref="TeamPanel"></TeamPanel>
    <ProjectAdd ref="ProjectAdd"></ProjectAdd>
  </div>
</template>

<script>


import TeamPanel from './Page/TeamPanel'
import ProjectAdd from './Page/TeamAdd'
import {DeleteTeam, fetchData, fetchRegexData} from "@/api/Aritcle/Team";

export default {
  components: {
    TeamPanel,
    ProjectAdd
  },
  data() {
    return {
      query: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      loading: true,
      tableData: [],
    };
  },
  mounted() {
    this.hadeFind()
  },
  methods: {
    removeTeam(TeamID) {
      this.$confirm('此操作将该团队删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        DeleteTeam({TeamID}).then(res => {
          if (res.code == 0) {
            this.$message.success(res.message);
            this.hadeFind()
          } else {
            this.$message.error(res.message);
          }
        })
      })
    },
    openDetailed(str) {
      this.$refs.TeamPanel.open(str)
    },
    openAdd() {
      this.$refs.ProjectAdd.open()
    },

    handleSearch() {
      this.loading = true
      let name = this.query.name
      fetchRegexData({name}).then(res => {
        if (res.code == 0) {
          let data = res.sum
          for (let i in data) {
            data[i].TeamMember = JSON.parse(data[i].TeamMember)
          }
          this.tableData = data
          this.query.pageTotal = 1
          this.loading = false
        } else {
          this.$message.error(res.message);
        }
      })
    },
    ResetSearch() {
      this.hadeFind()
    },
    hadeFind() {
      let skip = this.query.pageIndex
      this.tableData = []
      this.loading = true
      fetchData({skip}).then(res => {
        if (res.code == 0) {
          let data = res.sum
          for (let i in data) {
            data[i].TeamMember = JSON.parse(data[i].TeamMember)
          }
          this.tableData = data
          this.query.pageTotal = res.pageTotal
          this.loading = false
        } else {
          this.$message.error(res.message);
        }
      })
    },
    // 分页导航
    handlePageChange(val) {
      this.loading = true
      this.query.pageIndex = val
      this.hadeFind();
    }
  }
};
</script>

<style scoped lang="scss">
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}


.mr10 {
  margin-right: 10px;
}

.upload-block {
  ::v-deep .el-upload--text {
    width: auto;
    height: auto;
    border: none;
  }

  display: inline-block;
  float: left;
}

.avatar {
  display: inline-block;
  height: 60px;
  width: 160px;
  text-align: center;
  line-height: 40px;
  margin-left: 20px;

  span {
    color: #00a854;
    font-size: 12px;
    margin-right: 20px;
  }
}

.red {
  color: red;
}

.green {
  color: green;
}
</style>
