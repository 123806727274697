<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 资讯管理
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">添加</el-button>
      </div>
      <el-table
          v-loading="loading"
          :data="tableData"
          border
          height="400px"
          header-cell-class-name="table-header"
      >
        <el-table-column prop="Title" label="标题" width="180"></el-table-column>
        <el-table-column>
          <template #default="scope">
            <el-image :src="scope.row.ImageUrl" class="image"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="StartClass" label="分类" width="180"></el-table-column>
        <el-table-column prop="TitleSlogan" label="标语"></el-table-column>
        <el-table-column prop="NewTime" label="加入时间"></el-table-column>
        <el-table-column>
          <template #default="scope">
            <el-button type="text" @click="opeEdit(scope.row)">编辑</el-button>
            <el-button class="red" type="text" @click="Setdelete(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="query.pageIndex"
            :page-size="query.pageSize"
            :total="query.pageTotal"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
    <Add ref="Add"></Add>
    <Edit ref="Edit"></Edit>
  </div>
</template>

<script>

import Add from './Page/Add'
import Edit from './Page/Edit'
import {DeleteData, fetchData} from "@/api/Dynamic";

export default {
  data() {
    return {
      query: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      loading: true,
      tableData: [],
    };
  },
  mounted() {
    this.hadeFind()
  },
  methods: {
    opeEdit(str){
      this.$refs.Edit.open(str)
    },
    Setdelete(id){
      this.$confirm('是否确认删除该资讯？', "提示", {
        type: "warning"
      }).then(()=>{
        DeleteData({id}).then(res=>{
          if (res.code == 0) {
            this.$message.success(res.message);
            this.hadeFind()
          }else {
            this.$message.error(res.message);
          }
        })

      })
    },
    handleAdd() {
      this.$refs.Add.open()
    },
    closeSearch() {
      this.hadeFind()
    },
    hadeFind() {
      this.tableData = []
      let skip = this.query.pageIndex
      this.loading = true
      fetchData({skip}).then(res => {
        this.loading = false
        let sum = res.sum
        this.tableData = sum
      })
    },
    // 分页导航
    handlePageChange(val) {
      this.query.pageIndex = val
      this.hadeFind();
    }
  },
  components: {
    Add,
    Edit
  }
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.image {
  width: 100px;
  border-radius: 7px;
}

.red{
  color: red;
}
</style>
