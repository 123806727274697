<template>
  <el-dialog title="作品详情" v-model="view" width="1000px" :close-on-click-modal="false"
             :close-on-press-escape="false"
             :show-close="false">
    <div class="main" v-html="data">

    </div>

    <template #footer>
    <span class="dialog-footer">
           <el-button @click="close">关 闭</el-button>
    </span>
    </template>
  </el-dialog>
</template>

<script>


import {fetchWorkDetailed} from "@/api/Aritcle/Index";

export default {
  data() {
    return {
      view: false,
      data: ''
    }
  },
  methods: {
    open(Articles_id) {
      this.view = true
      fetchWorkDetailed({Articles_id}).then(res => {
        let sum = res.sum
        let ContentText = sum.ContentText
        this.data = ContentText
      })
    },
    close() {
      this.view = false
      this.data = ''
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  overflow: auto;
  height: 500px;
  user-select: none;
  padding: 0 20px;
}

.main::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

.main::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 20px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #535353;
}

.main::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  background: #ededed;
}
</style>