<template>
  <div class="login-wrap">
    <div class="ms-login">
      <div class="bg"></div>
      <div class="logo"></div>
      <div class="ms-title">万人添书后台管理</div>
      <div class="form Mobile">
        <div class="img">
          <img src="https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2021-4-30/Login/AdminUser.png">
        </div>
        <div class="set">
          <input placeholder="请输入手机号码" v-model="param.UserName">
        </div>
      </div>

      <div class="form Paws">
        <div class="img">
          <img src="https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2021-4-30/Login/AdminPaws.png">
        </div>
        <div class="set">
          <input placeholder="请输入密码" type="password" v-model="param.Password">
        </div>
      </div>

      <div class="button" @click="submitForm()">登陆</div>
    </div>
  </div>
</template>

<script>


import {LoginSet} from "@/api/Login";

export default {
  data() {
    return {
      param: {
        UserName: "",
        Password: ""
      },

      rules: {
        UserName: [
          {required: true, message: "请输入用户名", trigger: "blur"}
        ],
        Password: [
          {required: true, message: "请输入密码", trigger: "blur"}
        ]
      }
    };
  },
  created() {
    this.$store.commit("clearTags");
  },
  methods: {
    submitForm() {
      let UserName = this.param.UserName
      let Password = this.param.Password
      LoginSet({UserName, Password}).then(res => {
        if (res.code == 0) {
          let sum = res.sum
          sum = JSON.stringify(sum)
          localStorage.setItem('AdminUser', sum);
          this.$router.push("/");
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }

      })
    },
  }
};
</script>

<style scoped lang="scss">

.login-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: 100%;
  background-color: #ffffff;
}

.ms-login {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 800px;
  height: 450px;
  margin: -225px 0 0 -400px;
  border-radius: 5px;
  background: rgba(255, 255, 255);
  overflow: hidden;

  .bg {
    background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2021-4-30/Login/Adminbg.png");
    background-size: 100% 100%;
    width: 380px;
    height: 308px;
    position: absolute;
    left: 0;
    top: 74px;
  }

  .logo {
    background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2021-4-30/Login/titleImg.png");
    background-size: 100% 100%;
    width: 150px;
    height: 50px;
    position: absolute;
    left: 400px;
    top: 59px;
  }


  .ms-title {
    font-size: 16px;
    color: #000;
    position: absolute;
    left: 590px;
    top: 74px;
    font-weight: 200;
  }

  .Mobile {
    top: 170px;
  }

  .Paws {
    top: 250px;
  }

  .form {
    width: 370px;
    height: 50px;
    position: absolute;
    left: 400px;

    .img {
      width: 30px;
      height: 30px;
      position: absolute;
      left: 11px;
      top: 9px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .set {
      width: 310px;
      height: 50px;
      position: absolute;
      right: 0;

      input {
        background: none;
        border: none;
        width: 100%;
        height: 100%;
        outline: none;
        text-indent: 1rem;
        border-bottom: 1px solid #dededf;
      }

      input:focus {
        border: none;
        border-bottom: 1px solid #666666;
      }
    }
  }

  .button {
    width: 150px;
    height: 40px;
    line-height: 40px;
    border-radius: 10px;
    position: absolute;
    right: 50px;
    top: 335px;
    background-color: #ff725e;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
  }
}


//.black {
//  display: inline;
//  color: #000000;
//}
//

//


//.ms-content {
//  padding: 30px 30px;
//}
//
//.login-btn {
//  text-align: center;
//}
//
//.login-btn button {
//  width: 100%;
//  height: 36px;
//  margin-bottom: 10px;
//}
//
//.login-tips {
//  font-size: 12px;
//  line-height: 30px;
//  color: #fff;
//}
</style>
