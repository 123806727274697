import {createRouter, createWebHistory} from "vue-router";
import Home from "../views/Home.vue";

const routes = [
    {
        path: '/',
        redirect: '/dashboard'
    }, {
        path: "/",
        name: "Home",
        component: Home,
        children: [
            {
                path: "/dashboard",
                name: "dashboard",
                meta: {
                    title: '系统首页'
                },
                component: () => import ("../views/Dashboard.vue")
            }, {
                path: '/404',
                name: '404',
                meta: {
                    title: '找不到页面'
                },
                component: () => import ('../views/404.vue')
            }, {
                path: '/403',
                name: '403',
                meta: {
                    title: '没有权限'
                },
                component: () => import ('../views/403.vue')
            }, {
                path: '/User/index',
                name: '用户管理',
                meta: {
                    title: '用户管理'
                },
                component: () => import ('../views/User/index')
            },
            {
                path: '/Article/Project',
                name: '项目管理',
                meta: {
                    title: '项目管理'
                },
                component: () => import ('../views/Article/Project/Project')
            },
            {
                path: '/Article/Team',
                name: '团队管理',
                meta: {
                    title: '团队管理'
                },
                component: () => import ('../views/Article/Team/Team')
            },
            {
                path: '/Article/Index',
                name: '文章管理',
                meta: {
                    title: '文章管理'
                },
                component: () => import ('../views/Article/index/Index')
            },
            {
                path: '/Comment/index',
                name: '评论管理',
                meta: {
                    title: '评论管理'
                },
                component: () => import ('../views/Comment/index')
            }, {
                path: '/System/user',
                meta: {
                    title: '后台管理人员'
                },
                component: () => import ('../views/System/User')
            },
            {
                path: '/System/permission',
                meta: {
                    title: '权限管理'
                },
                component: () => import ('../views/System/Permission')
            },
            {
                path: '/System/resource',
                meta: {
                    title: '资源管理'
                },
                component: () => import ('../views/System/Resource')
            },
            {
                path: '/Dynamic/index',
                meta: {
                    title: '资讯管理'
                },
                component: () => import ('../views/Dynamic/index')
            },
        ]
    }, {
        path: "/login",
        name: "Login",
        meta: {
            title: '登录'
        },
        component: () => import (
            "../views/Login.vue")
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title}`;
    const role = localStorage.getItem('AdminUser');
    // this.$router.push("/");
    if (!role && to.path !== '/login') {
        next('/login');
    } else if (to.meta.permission) {
        // 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
        role === 'admin'
            ? next()
            : next('/403');
    } else {
        next();
    }
});

export default router;
