<template>
  <el-dialog v-model="view" width="900px" :close-on-click-modal="false"
             :close-on-press-escape="false"
             :show-close="false">
    <Tinymce ref="Tinymce" class="Tinymce"></Tinymce>
    <template #footer>
      <span class="dialog-footer">
          <el-button @click="save">保 存</el-button>
          <el-button @click="close">关 闭</el-button>
      </span>
    </template>

  </el-dialog>

</template>

<script>
import Tinymce from '@/components/Tinymce'

export default {
  data() {
    return {
      view: false
    }
  },
  components: {
    Tinymce
  },
  methods: {
    openTinymce(data) {
      this.view = true
      setTimeout(() => {
        this.$refs.Tinymce.newText(data)
      }, 100)
    },
    save() {
      let data = this.$refs.Tinymce.gutText()
      this.$refs.Tinymce.clear()
      this.view = false
      this.$parent.EditUpTextContent(data)
    },
    close() {
      this.$refs.Tinymce.clear()
      this.view = false
    }
  }
}
</script>

<style scoped lang="scss">
.Tinymce {
  width: 100%;
}
</style>