<template>
  <el-dialog title="添加成员" v-model="view" width="900px" :close-on-click-modal="false"
             :close-on-press-escape="false"
             :show-close="false">
    <div class="handle-box">
      <el-input v-model="query.name" placeholder="请输入搜索关键词" class="handle-input"></el-input>
      <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
      <el-button type="primary" icon="el-icon-close" @click="closeSearch">重置</el-button>
    </div>
    <el-table
        v-loading="loading"
        :data="tableData"
        border
        height="400"
        header-cell-class-name="table-header"
    >
      <el-table-column prop="Name" label="姓名" width="180"></el-table-column>
      <el-table-column prop="Mobile" label="手机号码"></el-table-column>
      <el-table-column prop="NewTime" label="加入时间"></el-table-column>
      <el-table-column>
        <template #default="scope">
          <el-button type="text" @click="AddUser(scope.row)">选中</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="query.pageTotal"
          @current-change="handlePageChange"
      ></el-pagination>
    </div>

    <template #footer>
    <span class="dialog-footer">
           <el-button @click="close">取 消</el-button>
    </span>
    </template>
  </el-dialog>
</template>

<script>

import {fetchData, fetchRegexData} from '../../../../api/User'

export default {
  data() {
    return {
      query: {
        name: '',
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      loading: true,
      view: false,
      tableData: [],
    };
  },
  mounted() {

  },
  methods: {
    open() {
      this.view = true
      this.hadeFind()
    },
    close() {
      this.view = false
      this.tableData = []
    },
    AddUser(str){
      this.$parent.AddUser(str)
      this.close()
    },
    closeSearch() {
      this.tableData = []
      this.hadeFind()
    },
    hadeFind() {
      this.tableData = []
      let skip = this.query.pageIndex
      this.query.name = ''
      this.loading = true
      fetchData({skip}).then(res => {
        if (res.code == 0) {
          this.tableData = res.sum
          this.query.pageTotal = res.pageTotal
          this.loading = false
        } else {
          this.$message.error(res.message);
        }
      })
    },
    //搜索
    handleSearch() {
      this.tableData = []
      this.loading = true
      let name = this.query.name
      fetchRegexData({name}).then(res => {
        if (res.code == 0) {
          this.tableData = res.sum
          this.query.pageTotal = 1
          this.loading = false
        } else {
          this.$message.error(res.message);
        }
      })
    },
    // 分页导航
    handlePageChange(val) {
      this.query.pageIndex = val
      this.hadeFind();
    }
  }
};
</script>

<style scoped lang="scss">
.handle-box {
  margin-bottom: 15px;
}

.handle-input {
  width: 200px;
  margin-right: 15px;
}
</style>