<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 项目查看与管理
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="container">
      <div class="handle-box">
        <el-button type="primary" icon="el-icon-plus" @click="handladd">新增项目</el-button>
      </div>
      <el-table
          v-loading="loading"
          :data="tableData"
          border
          header-cell-class-name="table-header"
      >
        <el-table-column prop="TitleNumber" label="活动期数" align="center"></el-table-column>
        <el-table-column prop="Title" label="活动昵称" align="center"></el-table-column>
        <el-table-column prop="ItemNumber" label="活动编号" align="center"></el-table-column>
        <el-table-column prop="StyleClass" label="状态" align="center"></el-table-column>
        <el-table-column prop="NewTime" label="创建日期" width="180" align="center"></el-table-column>
        <el-table-column width="180" align="center">
          <template #default="scope">
            <el-button
                type="text"
                @click="handleEdit( scope.row)"
            >编辑
            </el-button>
            <el-button
                type="text"
                class="red"
                :disabled="scope.row.disabled"
                @click="handleDelete( scope.row)"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="query.pageIndex"
            :page-size="query.pageSize"
            :total="query.pageTotal"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
    <ProjectEdit ref="ProjectEdit"></ProjectEdit>
    <ProjectAdd ref="ProjectAdd"></ProjectAdd>
  </div>
</template>

<script>
import ProjectEdit from '@/views/Article/Project/Page/ProjectEdit'
import ProjectAdd from '@/views/Article/Project/Page/ProjectAdd'
import {DeleteProject, DeleteProjectAll, fetchData} from "@/api/Aritcle/Project";

export default {
  components: {
    ProjectEdit,
    ProjectAdd
  },
  data() {
    return {
      loading: false,
      query: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      tableData: [],
    };
  },
  created() {
    this.hadeFind();
  },
  methods: {
    hadeFind() {
      let skip = this.query.pageIndex
      this.loading = true
      fetchData({skip}).then(res => {
        if (res.code == 0) {
          this.tableData = res.sum
          this.query.pageTotal = res.pageTotal
          this.loading = false
        } else {
          this.$message.error(res.message);
        }
      })
    },
    handladd() {
      this.$refs.ProjectAdd.open()
    },
    handleEdit(data) {
      this.$refs.ProjectEdit.open(data)
    },
    handleDelete(row) {
      this.$confirm('是否确认删除该项目？', "提示", {
        type: "warning"
      }).then(()=>{
        let id = row.id
        DeleteProject({id}).then(res => {
          if (res.code == 0) {
            this.$message.success(res.message);
            this.hadeFind()
          } else {
            this.$confirm(res.message, "提示", {
              type: "warning"
            }).then(() => {
              DeleteProjectAll({id}).then(str=>{
                this.$message.success(str.message);
              })
            })
          }
        })
      })

    },
    // 分页导航
    handlePageChange(val) {
      this.query.pageIndex = val
      this.getData();
    }
  }
};
</script>

<style scoped lang="scss">
.handle-box {
  margin-bottom: 10px;
}

.red {
  color: red;
}
</style>
