import request from '../../utils/request';

//列表普通查询
export const fetchData = query => {
    return request({
        url: process.env.VUE_APP_URL+'Admin/Dynamic/list',
        method: 'get',
        params: query
    });
};


//添加资讯
export const AddData = query => {
    return request({
        url: process.env.VUE_APP_URL+'Admin/Dynamic/add',
        method: 'post',
        data: query
    });
};


//删除资讯
export const DeleteData = query => {
    return request({
        url: process.env.VUE_APP_URL+'Admin/Dynamic/delete',
        method: 'post',
        data: query
    });
};


//编辑资讯
export const UpData = query => {
    return request({
        url: process.env.VUE_APP_URL+'Admin/Dynamic/up',
        method: 'post',
        data: query
    });
};
