<template>
  <div>
    <el-dialog title="添加资讯" v-model="view" width="800px" :close-on-click-modal="false"
               :close-on-press-escape="false"
               :show-close="false">
      <el-form label-width="100px">
        <el-form-item label="标题" :required="true">
          <el-input v-model="form.Title"></el-input>
        </el-form-item>
        <el-form-item label="标语" :required="true">
          <el-input v-model="form.TitleSlogan"></el-input>
        </el-form-item>
        <el-form-item label="封面图">
          <el-image
              v-if="form.ImageUrl"
              class="Imageheader"
              :src="form.ImageUrl"
              :preview-src-list="[form.ImageUrl]">
          </el-image>
          <el-upload
              class="upload-block"
              accept="image/png,image/jpg,image/jpeg"
              :action='actionUrl'
              :show-file-list="false"
              :on-success="ImageAdd">
            <el-button type="text" class="addImage">更换图片</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="展示类型" :required="true">
          <el-select v-model="form.StartClass" placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="链接地址" :required="true" v-if="form.StartClass=='链接地址'">
          <el-input v-model="form.Url"></el-input>
        </el-form-item>
        <el-form-item label="普通文本" :required="true" v-if="form.StartClass=='普通文本'">
          <el-button @click="openTinymce" type="text">内容编辑</el-button>
        </el-form-item>
      </el-form>
      <template #footer>
    <span class="dialog-footer">
        <el-button @click="save">添 加</el-button>
        <el-button @click="close">关 闭</el-button>
    </span>
      </template>
    </el-dialog>
    <TinymceView ref="TinymceView"></TinymceView>
  </div>
</template>

<script>

import {AddData} from "@/api/Dynamic";
import TinymceView from './TextEdit/TinymceView'
import {ActionUrl} from "@/api/Image";

export default {
  data() {
    return {
      actionUrl:'',
      options: [
        {label: '链接地址', value: '链接地址'},
        {label: '普通文本', value: '普通文本'}
      ],
      view: false,
      form: {}
    }
  },
  mounted() {
    this.actionUrl = ActionUrl()
  },
  methods: {
    openTinymce() {
      this.$refs.TinymceView.openTinymce(this.form.Text)
    },
    open() {
      this.view = true
    },
    saveTinymce(data) {
      this.form.Text = data
    },
    save() {
      let form = this.form
      AddData(form).then(res => {
        if (res.code == 0) {
          this.form = {}
          this.view = false
          this.$message.success(res.message)
          this.$parent.hadeFind()
        } else {
          this.$message.error(res.message);
        }
      })
    },
    close() {
      this.view = false
    },
    ImageAdd(data){
      if (data.code == 0) {
        this.$message({
          message: data.message,
          type: 'success'
        });
        this.form.ImageUrl = data.url
      } else {
        this.$message({
          message: data.message,
          type: 'warning'
        });
      }
    }
  },
  components: {
    TinymceView
  }

}
</script>

<style scoped lang="scss">
.Imageheader {
  width: 200px
}
.upload-block {
  ::v-deep .el-upload--text {
    width: auto;
    height: auto;
    border: none;
  }
}

</style>
