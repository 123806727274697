<template>
  <div>
    <el-dialog :title="data.Title" v-model="view" width="900px" :close-on-click-modal="false"
               :close-on-press-escape="false"
               :show-close="false">
      <el-form label-width="110px">
        <el-row>
          <el-col :span="7">
            <el-form-item label="活动期数">{{ data.TitleNumber }}</el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="活动编号">{{ data.ItemNumber }}</el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="状态">
              <el-select v-model="data.StyleClass" placeholder="请选择">
                <el-option
                    v-for="item in OptionsClass"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="显示方式">
              <el-select v-model="data.LookStart" placeholder="请选择">
                <el-option
                    v-for="item in OptionsLookStart"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="海报简介">
              <el-image
                  class="Imageposter"
                  :src="data.ImagePoster"
                  :preview-src-list="[data.ImagePoster]">
              </el-image>
              <el-upload
                  class="upload-block"
                  accept="image/png,image/jpg,image/jpeg"
                  :action='actionUrl'
                  :show-file-list="false"
                  :on-success="ImageAddPoster">
                <el-button type="text" class="addImage">更换图片</el-button>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="眉头横条">
              <el-image
                  class="Imageheader"
                  :src="data.ImageHeader"
                  :preview-src-list="[data.ImageHeader]">
              </el-image>
              <el-upload
                  class="upload-block"
                  accept="image/png,image/jpg,image/jpeg"
                  :action='actionUrl'
                  :show-file-list="false"
                  :on-success="ImageAddHeader">
                <el-button type="text" class="addImage">更换图片</el-button>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="注意事项">
        <span class="redWarning">
          【眉头横条】纵横比：200∶53 建议最低尺寸1922 × 514 px<br>
          【海报简介、引导简介】纵横比：27∶50 建议最低尺寸539 × 995 px
        </span>
        </el-form-item>

        <el-form-item label="简介文本">
          <div v-html="data.TextContent" class="TextContent"></div>
        </el-form-item>
        <el-form-item>
          <el-button @click="openTinymec">修改简介</el-button>
        </el-form-item>

      </el-form>

      <template #footer>
    <span class="dialog-footer">
        <el-button @click="save">保 存</el-button>
        <el-button @click="close">关 闭</el-button>
    </span>
      </template>
    </el-dialog>
    <TinymceView ref="TinymceView"></TinymceView>
  </div>
</template>

<script>

import TinymceView from '@/components/TinymceView'
import {ActionUrl} from "@/api/Image";
import {EditSaveUp} from "@/api/Aritcle/Project";

export default {
  data() {
    return {
      actionUrl: '',
      view: false,
      data: {},
      OptionsLookStart: [
        {
          value: '海报简介',
          label: '海报简介'
        },
        {
          value: '文本简介',
          label: '文本简介'
        },
      ],
      OptionsClass: [
        {
          value: '预告',
          label: '预告'
        },
        {
          value: '进行中',
          label: '进行中'
        },
        {
          value: '已完结',
          label: '已完结'
        }
      ]
    }
  },
  mounted() {
    this.actionUrl = ActionUrl()
  },
  methods: {
    EditUpTextContent(data) {
      this.data.TextContent = data
      this.$message({
        message: '修改成功',
        type: 'success'
      });
    },
    openTinymec() {
      this.$refs.TinymceView.openTinymce(this.data.TextContent)
    },
    ImageAddHeader(data) {
      if (data.code == 0) {
        this.$message({
          message: data.message,
          type: 'success'
        });
        this.data.ImageHeader = data.url
      } else {
        this.$message({
          message: data.message,
          type: 'warning'
        });
      }
    },
    ImageAddPoster(data) {
      if (data.code == 0) {
        this.$message({
          message: data.message,
          type: 'success'
        });
        this.data.ImagePoster = data.url
      } else {
        this.$message({
          message: data.message,
          type: 'warning'
        });
      }
    },
    ImageAddGuide(data) {
      if (data.code == 0) {
        this.$message({
          message: data.message,
          type: 'success'
        });
        this.data.ImageGuide = data.url
      } else {
        this.$message({
          message: data.message,
          type: 'warning'
        });
      }
    },
    save() {
      let data = this.data
      EditSaveUp({...data}).then(res => {
        if (res.code == 0) {
          this.$message({
            message: res.message,
            type: 'success'
          });
          this.close()
        } else {
          this.$message({
            message: res.message,
            type: 'warning'
          });
        }
      })
    },
    open(data) {
      this.data = JSON.parse(JSON.stringify(data))
      this.view = true
    },
    close() {
      this.data = {}
      this.view = false
    }
  },
  components: {
    TinymceView
  }
}
</script>

<style scoped lang="scss">
.Imageheader {
  width: 200px
}

.Imageposter {
  height: 100px;
}

.addImage {
  margin-top: 20px;
}

.TextContent {
  height: 300px;
  overflow-y: auto;
  padding: 0 30px;
}

.redWarning {
  color: red;
  font-size: 11px;
}

.TextContent::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

.TextContent::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 20px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #535353;
}

.TextContent::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  background: #ededed;
}

.upload-block {
  ::v-deep .el-upload--text {
    width: auto;
    height: auto;
    border: none;
  }
}
</style>