import request from "../utils/request";

export const ActionUrl = () => {
    return process.env.VUE_APP_URL+'Admin/Image/add'
};


export const UpDataImg = (headers,query) => {
    return request({
        url:process.env.VUE_APP_URL+ 'Admin/Image/add',
        headers:headers,
        method: 'post',
        data: query
    });
};